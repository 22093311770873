import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import HomePage from './Pages/HomePage';
import Realisations from './Pages/Realisations';

function App() {
  
  return (
    <Router basename="/">
      <Routes>
        <Route exact path="/" element={<HomePage/>}/>
        <Route path="/realisations" element={<Realisations/>}/>
      </Routes>
    </Router>
  );
}

export default App;
