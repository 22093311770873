import React, {useState, useEffect, useContext} from 'react'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import { FirebaseContext } from './firebase'

export default function ProjectCard(props) {

    const firebase = useContext(FirebaseContext)

    const [Cover, setCover] = useState('');

    useEffect(() => {
        firebase.getPhotos(props.title).onSnapshot((doc) => {
            if (doc.data().images !== undefined) {
                setCover(doc.data().images[0].url)
            }
        })
    }, []);

  return (
      <>
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="150"
                    image={Cover}
                    alt="green iguana"
                    id={props.title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" id={props.title}>
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" id={props.title}>
                        {props.content}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
      </>
  );
}