import React from "react";
import emailjs from 'emailjs-com'
import { TextField, Button } from "@mui/material";

var typefaceRajdhani = require("typeface-rajdhani")

class Contact extends React.Component {


    state = {
        name: '',
        firstname: '',
        email: '',
        num: '',
        message: ''
    }

    sendEmail(e) {
        e.preventDefault();
        const { name, firstname, email, num, message } = this.state

        if (email.indexOf('@') !== -1 && name !== '' && firstname !== '' && message !== '' && num !== '') {
            emailjs.sendForm('service_sb758fo', 'template_x0hiewn', e.target, 'nwZ7x5GJMO1aYd14t')
            .then((result) => {
                console.log(result.text);
                this.resetForm()
                alert("Votre message à été envoyé");
            }, (error) => {
                console.log(error.text);
            });
        } else {
            window.alert('Impossible d\'envoyer le message :\nUn ou plusieurs champs sont vides ou incorect')
        }
    }

    resetForm() {
        this.setState({
            name: '',
            firstname: '',
            email: '',
            num: '',
            message: ''
      })
    }

    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }

    render() {
        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                <form style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "2vw", borderRadius: "20px", justifyContent: "center"}} onSubmit={this.sendEmail.bind(this)}>
                    <h3 style={{fontFamily: typefaceRajdhani, fontWeight: "lighter"}}>contactez nous</h3>
                    <TextField onChange={this.handleChange.bind(this, 'name')} style={{width: "30vh", marginBottom: "2vh"}} fullWidth type="name" name="user_name" class="form-control" id="lastName" placeholder="Entrez votre nom" value={this.state.name}></TextField>
                    <TextField onChange={this.handleChange.bind(this, 'firstname')} style={{width: "30vh", marginBottom: "2vh"}} fullWidth type="name" name="user_firstname" class="form-control" id="firstName" placeholder="Entrez votre prénom" value={this.state.firstname}></TextField>
                    <TextField onChange={this.handleChange.bind(this, 'email')} style={{width: "30vh", marginBottom: "2vh"}} fullWidth type="email" name="user_email" class="form-control" id="mail" aria-describedby="emailHelp" placeholder="Entrez votre E-mail" value={this.state.email}></TextField>
                    <TextField onChange={this.handleChange.bind(this, 'num')} style={{width: "30vh", marginBottom: "2vh"}} fullWidth type="text" name="user_num" class="form-control" id="num" aria-describedby="numHelp" placeholder="Entrez votre numéro" value={this.state.num}></TextField>
                    <TextField onChange={this.handleChange.bind(this, 'message')} style={{width: "40vh", marginBottom: "2vh"}} fullWidth multiline maxRows={5} class="form-control" name="message" id="exampleFormControl1" rows="10" placeholder="Entrez votre message" value={this.state.message}></TextField>
                    <Button type="submit" variant="contained" style={{backgroundColor: "#B33718"}}>Envoyer</Button>
                </form>
            </div>
        )
    }
}

export default Contact;