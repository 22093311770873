import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function Topbar(props) {

    var typefaceRajdhani = require("typeface-rajdhani")

    function goToHome() {
        window.location.href = "/"
    }

    return (
        <Box sx={{ flexGrow: 1}}>
            <AppBar position="static" sx={{backgroundColor: "white"}}>
                <Toolbar style={{display: "flex", justifyContent: "space-between"}}>
                    <img src='/Logo.png' style={{height: '7vh'}} onClick={goToHome}/>
                    <div>
                        <a style={props.accueil} type="submit" href="/">Accueil</a>
                        <a style={props.real} type="submit" href="/realisations">Réalisations</a>
                        <a style={{fontFamily: typefaceRajdhani, color: 'black', align: 'right', marginLeft: '20px', textDecoration: "none", fontSize: "2vh"}} href="/#scrollspyContact">Contact</a>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}