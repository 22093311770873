import React, {useState, useContext, useEffect} from "react";
import RealCard from "../Components/RealCard";

import Topbar from "../Components/Topbar";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Footer from "../Components/Footer";
import ProjectCard from '../Components/ProjectCard';

import { FirebaseContext } from '../Components/firebase'

import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  

export default function Realisations() {

    var typefaceRajdhani = require("typeface-rajdhani")

    const firebase = useContext(FirebaseContext)
    const [Project, setProject] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [Images, setImages] = useState([]);
    const [ProjectId, setProjectId] = useState('');
    const [Description, setDescription] = useState('');
    const [Title, setTitle] = useState('');
    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState('')

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    
    useEffect(() => {
        firebase.getProjects().onSnapshot((snapshot) => {
            const tempProjects = []
            snapshot.forEach(doc => {
                tempProjects.push({...doc.data(), id: doc.id})
            })
            setProject(tempProjects)
        })
    }, []);

    const handleOpen = (e) => {
        firebase.getPhotos(e.target.id).onSnapshot((doc) => {
            setImages(doc.data().images || [])
            setDescription(doc.data().content)
            setTitle(doc.data().title)
        })
        setProjectId(e.target.id)
        setOpen(true)
    }

    return (
        <div>
            <Topbar accueil={{fontFamily: typefaceRajdhani, color: 'black', align: 'right', textDecoration: "none", fontSize: "2vh"}} real={{fontFamily: typefaceRajdhani, color: '#B33718', align: 'right', marginLeft: '20px', textDecoration: "none", fontWeight: "bold", fontSize: "2vh"}}/>
            <Grid container spacing={2} sx={{ flexDirection: { xs: "column", md: "row"}, marginTop: "2vw" }}>
                {
                    Project.map(Project => (
                        <Grid item xs={12} md={4} style={{display: "flex", justifyContent: "center"}} key={Project.title} onClick={handleOpen} id={Project.id}>
                            <ProjectCard img={Project.image} title={Project.title} content={Project.content}/>
                        </Grid>
                    ))
                }
            </Grid>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: "#B33718" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            >
                            <CloseIcon />
                        </IconButton>
                        <Typography>{ProjectId}</Typography>
                    </Toolbar>
                </AppBar>
                <div style={{display: "flex", justifyContent: "start", padding: "2vh"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <h1 style={{fontWeight: "lighter"}}>{Title}</h1>
                        <p style={{fontWeight: "lighter"}}>{Description}</p>   
                    </div>
                </div>
                <div style={{padding: "1vw", justifyContent: "space-around"}}>
                    {
                        Images.map((image) => (
                            <>
                            <img onClick={() => {setModalData(image.url); handleOpenModal()}} key={image.name} src={image.url} alt="album" style={{height: "30vh", marginLeft: "1vw"}}></img>
                            <Dialog
                                open={openModal}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                    <img src={modalData} alt="selectedPicture" style={{maxHeight: "70vh"}} ></img>
                            </Dialog>
                            </>
                        ))
                    }

                </div>
            </Dialog>
            <Footer/>
        </div>
    )
}