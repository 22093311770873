import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export default function Imagelist() {
  return (
    <ImageList sx={{ width: '70vw', height: "90vw", fontSize: "0.1vw"}}>
      <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div"></ListSubheader>
      </ImageListItem>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: 'cuisine.jpg',
    title: 'cuisines',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'porte.jpg',
    title: 'portes',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'sdb.jpg',
    title: 'salle de bain',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'sdb 2.jpg',
    title: 'salle de bain',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'table.jpg',
    title: 'meubles',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'terasse.jpg',
    title: 'terasses',
    rows: 2,
    cols: 2,
    featured: true,
  },
];