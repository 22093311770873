import React from "react";

import Topbar from "../Components/Topbar";
import Button from '@mui/material/Button';
import Imagelist from "../Components/Imagelist";
import Footer from "../Components/Footer";
import Contact from "../Components/contact";

export default function HomePage() {

    var typefaceRajdhani = require("typeface-rajdhani")

    const goToReal = () => {
        window.location.href = '/realisations'
    }

    return (
        <div>
            <Topbar accueil={{fontFamily: typefaceRajdhani, color: '#B33718', align: 'right', textDecoration: "none", fontWeight: "bold", fontSize: "2vh"}} real={{fontFamily: typefaceRajdhani, color: 'black', align: 'right', marginLeft: '20px', textDecoration: "none", fontSize: "2vh"}}/>
            <div style={{display: "flex", height: '40vw'}}>
                <img style={{position: 'absolute', right: '0', width: '70vw', zIndex: "1"}} src='/cover0.jpg' onClick={goToReal}/>
                <div style={{alignSelf: "center", marginLeft: "10vw", zIndex: "10", fontSize: "1.5vw"}}>
                    <h1 style={{fontFamily: typefaceRajdhani, backgroundColor: "#3F4040", color: "white", fontWeight: 'lighter', paddingLeft: '10px', paddingRight: "10px", width: "13.5vw"}}>Découvrez</h1>  
                    <h1 style={{fontFamily: typefaceRajdhani, backgroundColor: "#3F4040", color: "white", fontWeight: 'lighter', paddingLeft: '10px', paddingRight: "10px"}}>Nos différentes réalisations</h1>  
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: '5vh'}}>
                <h1 style={{fontFamily: typefaceRajdhani, fontWeight: "lighter", margin: "unset", fontSize: "3vw"}}>Réalisations</h1>    
                <h3 style={{fontFamily: typefaceRajdhani, fontWeight: "lighter", margin: "unset", fontSize: "2vw"}}>Découvrez les différents projets et réalisations des menuisiers romands</h3>    
                <Button onClick={goToReal} variant="contained"  style={{backgroundColor: "#B33718", '&:hover': {bcakgroundColor: '#B33718'}, fontFamily: typefaceRajdhani, marginTop: "2vh"}}>Découvrir</Button>
            </div>
            <div style={{display: "flex", justifyContent: "center", marginTop: "2vh"}}>
                <Imagelist/>
            </div>
            <Contact/>
            <Footer/>
        </div>
    )
}