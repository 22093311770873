import app from "firebase/app";
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
  apiKey: "AIzaSyA8DfAZahEo9y1HPni-PLSaLyeSQVc2N_o",
  authDomain: "les-menuisiers-romands.firebaseapp.com",
  projectId: "les-menuisiers-romands",
  storageBucket: "les-menuisiers-romands.appspot.com",
  messagingSenderId: "791226417933",
  appId: "1:791226417933:web:d023100c24e68a2b942644",
  measurementId: "G-43WG010KRM"
};

class Firebase {
    constructor() {
        app.initializeApp(config)
        this.auth = app.auth()
        this.db = app.firestore()
        this.storage = app.storage()
    }

    //login
    loginUser = (email, password) => this.auth.signInWithEmailAndPassword(email, password)

    //logout
    logoutUser = () => this.auth.signOut()

    // get projects
    getProjects = () => this.db.collection('projects')

    // delete project
    deleteProject = (id) => this.db.collection('projects').doc(`${id}`)

    // get description
    getDescription = (id) => this.db.collection('projects').doc(`${id}`)

    // new project
    newProject = (name) => this.db.collection('projects').doc(`${name}`)

    // get photos
    getPhotos = (id) => this.db.collection('projects').doc(`${id}`)

    // setImage
    setPhotos = (id) => this.db.collection('projects').doc(`${id}`)

    SetStorageRef = () => this.storage.ref()

}

export default Firebase