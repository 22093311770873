import React from "react";
import Divider from '@mui/material/Divider';

export default function Footer() {

    var typefaceRajdhani = require("typeface-rajdhani")

    return (
        <div style={{display: "flex", backgroundColor: "#3F4040", flexDirection: "column", marginTop: "2vw"}} id="scrollspyContact">
            <h3 style={{color: "white", alignSelf: "center", fontWeight: "lighter", fontFamily: typefaceRajdhani, fontSize: "2vh"}} >Les Menuisiers Romands Sàrl</h3>
            <txt style={{color: "white", alignSelf: "center", fontWeight: "lighter", fontFamily: typefaceRajdhani, fontSize: "1.5vh"}}>Route de Pradelaman 14</txt>
            <txt style={{color: "white", alignSelf: "center", fontWeight: "lighter", fontFamily: typefaceRajdhani, fontSize: "1.5vh"}}>CH-1971 Grimisuat</txt>
            <Divider sx={{width: "50vw", alignSelf: "center", marginTop: "1vw", marginBottom: "1vw", backgroundColor: "white"}}/>
            <a style={{color: "white", alignSelf: "center", fontWeight: "lighter", fontFamily: typefaceRajdhani, fontSize: "1.5vh", marginBottom: "2vw"}} href="tel:+41792211080">079 221 10 80</a>
            <txt style={{color: "white", alignSelf: "center", fontWeight: "lighter", fontFamily: typefaceRajdhani, fontSize: "1vh"}}>Copyright © 2022 Les menuisiers romands. Tout droit réservé</txt>
        </div>
    )
}